<template>
  <div>
    <div class="center" style="padding: 0 20px;" :style="'width:'+$setWidth">
      <div class="node_box1">
        <div class="node_title">—— {{$t('receivingDetails.title')}} ——</div>

        <div class="node_box2 flex_lr" v-for="(item,index) in 10" :key="index" style="margin:10px 0;">
          <div class="node_text">2022-08-22 13:13</div>
          <div class="node_text2">2.3900MT</div>
        </div>

      </div>
    </div>
    <div style="height: 20px;"></div>
  </div>
</template>
 
<script>
import walletHelper from "@/utils/walletHelper.js"
export default {
  data () {
    return {
      list:[],
      current_page:0,
      last_page:0,
      is_next:false,
    }
  },
  mounted () {
    // this.init()
  },
  created() {
    window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动事件
  },
  destroyed() {
    window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
  },
  methods: {
    init() {
      if(this.is_next)return
      this.is_next = true
      this.get('', {page:this.current_page}, 'POST').then(res => {
        this.is_next = false
        this.last_page = res.list.last_page;//分页总数
        this.current_page = res.list.current_page;//当前页
        if (this.current_page==1) {
          this.list = res.list.data
        } else {
          if(res.list.data.length>0){
            this.list = this.list.concat(res.list.data)
          }
        }
      })

      
    },
    Scrollbottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.next_pages()
      }
    },
    next_pages(){
      if(this.tableData!=undefined&&this.tableData.length>0){
				this.current_page++
				if (this.last_page<this.current_page) {
					return
				}
				this.init()
			}
    },
  }
}
</script>
 
<style lang="scss" scoped>
  .node_icon{
    width: 15px;
    margin-right: 10px;
  }
  .node_title{
    color: #ffffff;
    font-size: 1.1rem;
    padding: 10px 0;
    text-align: center;
  }
  .node_text{
    color: #ffffff;
    font-size: 0.8rem;
    padding: 8px 0;
  }
  .node_text2{
    color: #00FFEA;
    font-size: 0.8rem;
    padding: 8px 0;
  }
  .node_box1{
    width: 100%;
    background: #0C2379;
    border-radius: 8px;
    padding: 10px 13px;
    margin: 15px 0;
  }
  .node_box2{
    width: 100%;
    background: #041453;
    border-radius: 8px;
    padding: 5px 13px;
  }
</style>